@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');

:root {
    --font-family: 'Nanum Gothic Coding', Arial, sans-serif;
    --dark-bg: #121212;
    --light-text: #ffffff;
    --dark-element-bg: #333;
    --border-radius: 8px;
    --shadow: 0 0 10px #ccc;
    --padding-half-rem: 0.5rem;
}

html {
    overflow-y: scroll;
}

body {
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
    background-color: var(--dark-bg);
    color: var(--light-text);
}

h1 {
    font-family: var(--font-family);
    background-color: var(--dark-element-bg);
    color: var(--light-text);
    text-align: left;
    padding: 1rem;
    border-radius: var(--border-radius);
    font-size: 3rem;
}

ul {
    list-style: none;
    padding: 0;
    font-size: large;
}

li ul {
    padding-left: 1rem;

}

li ul li {
    list-style: disc;
}

img {
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
}

a {
    display: inline-block;
    background-color: var(--dark-element-bg);
    color: var(--light-text);
    padding: var(--padding-half-rem);
    border-radius: var(--border-radius);
    text-decoration: none;
    font-size: large;
}

p {
    font-size: large;
}

a:hover,
.nav-link.active {
    background-color: #555;
}

button {
    background-color: var(--dark-element-bg);
    color: var(--light-text);
    padding: var(--padding-half-rem);
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-top: var(--padding-half-rem);
    width: 100%;
}

.project-button {
    font-size: 100%;
    font-family: var(--font-family);
}

.project-frame {
    padding: 1rem;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    margin-top: 1rem;
    border: 1px solid #ccc;
}

.container {
    max-width: 80%;
    margin: auto;
}

nav ul {
    display: flex;
    gap: 5px;
}

.skill-block {
    margin: 1rem;
    padding: 0;
    border: 0.13rem solid #ccc;
    border-radius: 10%;
    width: 6.25rem;
    height: 6.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.skill-block-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.skill-name {
    font-size: 1rem;
    text-align: center;
}

.skill-block:hover {
    transform: scale(1.1);
}

.skill-block svg {
    max-width: 80%;
    max-height: 80%;
    height: auto;
}